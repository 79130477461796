import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import Loader from "react-loaders";
import Dashboard from "./Dashboard";
import PageSample from "./PageSample";
import PemasukanBarang from "./Laporan/PemasukanBarang";
import UserLog from "./AdminMenu/UserLog";
import PengeluaranBarang from "./Laporan/PengeluaranBarang";
import UploadPengeluaran from "./AdminMenu/UploadPengeluaran";
import UploadPemasukan from "./AdminMenu/UploadPemasukan";
import MutasiBahanPenolong from "./Laporan/MutasiBahanPenolong";
import MutasiBarangJadi from "./Laporan/MutasiBarangJadi";
import MutasiMesin from "./Laporan/MutasiMesin";
import MyActivity from "./Extra/MyActivity";
import { useSelector } from "react-redux";
import ChangePassword from "./Extra/ChangePassword";
import MutasiBahanBaku from "./Laporan/MutasiBahanBaku";
import MutasiBarangScrap from "./Laporan/MutasiBarangScrap";
import PosisiWIP from "./Laporan/PosisiWIP";
import MutasiPeralatan from "./Laporan/MutasiPeralatan";
import UploadBarangReject from "./AdminMenu/UploadBarangReject";
import MasterUser from "./AdminMenu/MasterUser";
import MutasiBarangReject from "./Laporan/MutasiBarangReject";
import UnauthorizedPage from "./Extra/UnauthorizedPage";
import EximNote from "./AdminMenu/EximNote/EximNote";

export default function Contents() {
  const auth = useSelector((state) => state.auth);
  return (
    <div>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Please wait...</h6>
            </div>
          </div>
        }
      >
        <Route path="/dashboard" component={Dashboard} />
      </Suspense>
      <Route path="/my-activity" component={MyActivity} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/pemasukan-barang" component={PemasukanBarang} />
      <Route path="/pengeluaran-barang" component={PengeluaranBarang} />
      <Route path="/mutasi-bahan-baku" component={MutasiBahanBaku} />
      <Route path="/mutasi-bahan-penolong" component={MutasiBahanPenolong} />
      <Route path="/mutasi-barang-jadi" component={MutasiBarangJadi} />
      <Route path="/mutasi-mesin" component={MutasiMesin} />
      <Route path="/mutasi-barang-scrap" component={MutasiBarangScrap} />
      <Route path="/posisi-wip" component={PosisiWIP} />
      <Route path="/mutasi-peralatan" component={MutasiPeralatan} />
      <Route path="/mutasi-barang-reject" component={MutasiBarangReject} />
      <Route path="/unauthorized-page" component={UnauthorizedPage} />
      {/* Admin Menu */}
      {auth.signedIn ? (
        auth.user.role_level > 1 ? (
          <>
            <Route path="/users" component={MasterUser} />
            <Route path="/user-log" component={UserLog} />
            <Route path="/exim-notes" component={EximNote} />
            <Route path="/upload-pengeluaran" component={UploadPengeluaran} />
            <Route path="/upload-pemasukan" component={UploadPemasukan} />
            <Route
              path="/upload-barang-reject"
              component={UploadBarangReject}
            />
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Please wait...</h6>
            </div>
          </div>
        }
      >
        <Route path="/pages/sample" component={PageSample} />
      </Suspense>
      <Route
        exact
        path="/pages"
        render={() => <Redirect to="/pages/sample" />}
      />
      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
    </div>
  );
}
