import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "./PageTitle";
import moment from "moment";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import DPemasukan from "./DashboardComp/DPemasukan";
import Loader from "react-loaders";
import DPengeluaran from "./DashboardComp/DPengeluaran";

export default function Dashboard() {
  const dari = moment().startOf("year");
  const dari_bulan = moment().startOf("month");
  const sampai = moment();
  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  const loaderBallRise = (
    <div className="mx-auto mb-4 mt-4 d-block">
      <Loader type="ball-pulse-rise" />
    </div>
  );
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Dashboard"
          subheading="Overview dokumen BC."
          icon="lnr-home icon-gradient bg-mean-fruit"
        />
        <Tabs
          defaultActiveKey="1"
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          <TabPane tab="Pemasukan Barang" key="1">
            <DPemasukan
              formatNumber={formatNumber}
              dari={dari}
              dari_bulan={dari_bulan}
              sampai={sampai}
              loader={loaderBallRise}
            />
          </TabPane>
          <TabPane tab="Pengeluaran Barang" key="2">
            <DPengeluaran
              formatNumber={formatNumber}
              dari={dari}
              dari_bulan={dari_bulan}
              sampai={sampai}
              loader={loaderBallRise}
            />
          </TabPane>
        </Tabs>
      </CSSTransitionGroup>
    </Fragment>
  );
}
