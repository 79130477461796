import React, { Fragment, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Contents from "./Contents";
import { useSelector } from "react-redux";
import Login from "../Auth/Login";
import { Redirect, Route } from "react-router-dom";

const MainLayout = () => {
  const auth = useSelector((state) => state.auth);
  const [signedIn, setSignedIn] = useState(auth.signedIn);
  useEffect(() => {
    setSignedIn(auth.signedIn);
  }, [auth]);
  if (signedIn) {
    return (
      <Fragment>
        <Header />
        <div className="app-main">
          <Sidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Contents />
            </div>
            <Footer />
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Route path="/login" component={Login} />
        <Route path="/" render={() => <Redirect to="/login" />} />
      </Fragment>
    );
  }
};

export default MainLayout;
