import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { useSelector } from "react-redux";
import Axios from "../../../Axios";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export default function MyActivity() {
  const auth = useSelector((state) => state.auth);
  const [tblDat, setTblDat] = useState([]);
  const [tblLoad, setTblLoad] = useState(false);

  useEffect(() => {
    setTblLoad(true);
    Axios.get(
      `${auth.endPoint}user-log/personal-log?api_token=${auth.user.api_token}`
    )
      .then(({ data }) => setTblDat(data))
      .finally(() => setTblLoad(false));
  }, [auth.endPoint, auth.user.api_token]);
  const getData = () => {
    setTblLoad(true);
    Axios.get(
      `${auth.endPoint}user-log/personal-log?api_token=${auth.user.api_token}`
    )
      .then(({ data }) => setTblDat(data))
      .finally(() => setTblLoad(false));
  };
  const columns = [
    {
      Header: "Name",
      accessor: "user_name",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Activity",
      accessor: "activity",
    },
    {
      Header: "Origin",
      accessor: "origin",
    },
    {
      Header: "Waktu",
      accessor: "waktu",
    },
  ];

  return (
    <div>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="My Activity"
          subheading="Log aktivitas user"
          icon="pe-7s-repeat icon-gradient bg-dark"
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>User Activity</CardTitle>
                <CardSubtitle>{auth.user.name}</CardSubtitle>
                <Button
                  className="mb-3"
                  id="btnRefresh"
                  size="sm"
                  color="success"
                  disabled={tblLoad}
                  onClick={getData}
                >
                  <FontAwesomeIcon icon={faSync} spin={tblLoad} />
                  <UncontrolledTooltip target="btnRefresh">
                    Refresh Data
                  </UncontrolledTooltip>
                </Button>
                <ReactTable
                  data={tblDat}
                  columns={columns}
                  loading={tblLoad}
                  defaultPageSize="10"
                  filterable
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </div>
  );
}
