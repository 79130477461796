import Axios from "../../../Axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Card, CardHeader, Col, Container, Progress, Row } from "reactstrap";
import CountUp from "react-countup";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function DPengeluaran({ formatNumber, dari, sampai, loader }) {
  const auth = useSelector((state) => state.auth);
  const [pengeluaran, setPengeluaran] = useState([]);
  const [pengeluaranLoad, setPengeluaranLoad] = useState(false);
  const [totalBcPengeluaran, setTotalBcPengeluaran] = useState(0);
  const [bcs, setBcs] = useState([]);
  const [dataPerBc, setDataPerBc] = useState();

  useEffect(() => {
    const dari = moment().startOf("year");
    const sampai = moment();
    setPengeluaranLoad(true);
    Axios.post(auth.endPoint + "it-inventory/dashboard/pengeluaran-barang", {
      api_token: auth.user.api_token,
      tgl_dari: dari.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
    })
      .then(({ data }) => {
        setPengeluaran(data.data);
        setTotalBcPengeluaran(data.total);
        setBcs(data.bcs);
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => setPengeluaranLoad(false));
  }, [auth.endPoint, auth.user.api_token]);

  useEffect(() => {
    const dari_bulan = moment().startOf("month");
    const sampai = moment();
    let bcdt = [];
    let bar = new Promise((resolve) => {
      bcs.forEach((b, i, ar) => {
        if (b === "") {
          if (auth.user.role_level < 2) {
            return;
          }
        }
        Axios.post(`${auth.endPoint}it-inventory/dashboard/pengeluaran-perbc`, {
          api_token: auth.user.api_token,
          tgl_dari: dari_bulan.format("YYYY-MM-DD"),
          tgl_sampai: sampai.format("YYYY-MM-DD"),
          bc: b,
        })
          .then(({ data }) => {
            const a = {
              bc: b !== "" ? b : "JENIS BC TIDAK DIINPUT",
              data: data,
            };
            bcdt.push(a);
          })
          .finally(() => {
            if (i === ar.length - 1) resolve();
          })
          .catch((er) => "");
      });
    });
    bar.then(() => {
      console.log(bcdt);
      setDataPerBc(bcdt);
    });
  }, [auth.endPoint, auth.user.api_token, auth.user.role_level, bcs]);

  return (
    <Container fluid>
      <h5 className="text-primary mt-3 mb-3">
        BC Tanggal{" "}
        {dari.format("DD MMM YYYY") + " ~ " + sampai.format("DD MMM YYYY")}
      </h5>
      <Row className="mb-4">
        {!pengeluaranLoad
          ? pengeluaran.length
            ? pengeluaran.map((m, i) => {
                let ret = "";
                if (m.bc === "JENIS BC TIDAK DIINPUT") {
                  if (auth.user.role_level > 1) {
                    ret = (
                      <Col key={i} md="6" xl="4">
                        <div className="card mb-3 widget-content">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="widget-heading">{m.bc}</div>
                                <div className="widget-subheading">
                                  {dari.format("DD-MMM")} ~{" "}
                                  {sampai.format("DD-MMM")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-success">
                                  <CountUp
                                    useEasing
                                    formattingFn={(n) => formatNumber(n)}
                                    duration={3}
                                    end={m.count}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="widget-progress-wrapper">
                              <Progress
                                className="progress-bar-sm"
                                color="primary"
                                value={Math.round(
                                  (m.count / totalBcPengeluaran) * 100
                                )}
                              />
                              <div className="progress-sub-label">
                                <div className="sub-label-left">Persentase</div>
                                <div className="sub-label-right">
                                  {Math.round(
                                    ((m.count / totalBcPengeluaran) * 100 +
                                      Number.EPSILON) *
                                      100
                                  ) / 100}
                                  %
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  }
                } else {
                  ret = (
                    <Col key={i} md="6" xl="4">
                      <div className="card mb-3 widget-content">
                        <div className="widget-content-outer">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                              <div className="widget-heading">{m.bc}</div>
                              <div className="widget-subheading">
                                {dari.format("DD-MMM")} ~{" "}
                                {sampai.format("DD-MMM")}
                              </div>
                            </div>
                            <div className="widget-content-right">
                              <div className="widget-numbers text-success">
                                <CountUp
                                  useEasing
                                  formattingFn={(n) => formatNumber(n)}
                                  duration={3}
                                  end={m.count}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="widget-progress-wrapper">
                            <Progress
                              className="progress-bar-sm"
                              color="primary"
                              value={Math.round(
                                (m.count / totalBcPengeluaran) * 100
                              )}
                            />
                            <div className="progress-sub-label">
                              <div className="sub-label-left">Persentase</div>
                              <div className="sub-label-right">
                                {Math.round(
                                  ((m.count / totalBcPengeluaran) * 100 +
                                    Number.EPSILON) *
                                    100
                                ) / 100}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }
                return ret;
              })
            : ""
          : loader}
      </Row>
      <h5 className="mb-3 mt-3 text-primary">
        Ringkasan BC Bulan {sampai.format("MMMM")}
      </h5>
      <Row>
        {dataPerBc
          ? dataPerBc.map((i) => {
              return (
                <Col md="6" lg="6" sm="12">
                  <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
                    <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                      <div>
                        <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                          {i.bc}
                        </h5>
                        <h6 className="widget-subheading mb-0 opacity-5">
                          1 - {sampai.format("DD MMM YYYY")}
                        </h6>
                      </div>
                    </CardHeader>
                    <div className="widget-chart widget-chart2 text-left p-0">
                      <div className="widget-chat-wrapper-outer">
                        <div className="widget-chart-content pt-3 pr-3 pl-5">
                          <div className="widget-chart-flex">
                            <div className="widget-numbers">
                              <div className="widget-chart-flex">
                                <div className="text-primary">
                                  <CountUp
                                    start={0}
                                    end={i.data.total}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                    prefix=""
                                    duration="5"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                          <ResponsiveContainer height={114}>
                            <AreaChart
                              data={i.data.data}
                              margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <XAxis dataKey="tgl" />
                              <YAxis dataKey="jml" />
                              <Tooltip />
                              <Area
                                type="monotoneX"
                                dataKey="jml"
                                stroke="var(--primary)"
                                strokeOpacity=".8"
                                strokeWidth={3}
                                fill="var(--primary)"
                                fillOpacity=".08"
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })
          : ""}
      </Row>
    </Container>
  );
}
