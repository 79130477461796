import React, { Fragment, useEffect, useState } from "react";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Axios from "../../../Axios";
import { faPenAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Swal from "sweetalert2";

export default function ListBarangReject({ auth }) {
  const [tblData, setTblData] = useState([]);
  const [tblLoad, setTblLoad] = useState(false);
  const [mdEdit, setMdEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [winHeight, setWinHeight] = useState(window.innerHeight / 1.5);
  const [editLoad, setEditLoad] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWinHeight(window.innerHeight / 1.5);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWinHeight(window.innerHeight / 1.5);
      });
    };
  }, []);

  useEffect(() => {
    setTblLoad(true);
    Axios.get(
      `${auth.endPoint}it-inventory/mutasi-barang-reject?api_token=${auth.user.api_token}`
    )
      .then(({ data }) => setTblData(data))
      .finally(() => setTblLoad(false))
      .catch((er) => {
        toast.error("Pengambilan data error");
        console.log(er);
      });
  }, [auth.endPoint, auth.user.api_token]);

  const toggleMdEdit = () => {
    setMdEdit(false);
    setEditData();
  };

  const getData = () => {
    setTblLoad(true);
    Axios.get(
      `${auth.endPoint}it-inventory/mutasi-barang-reject?api_token=${auth.user.api_token}`
    )
      .then(({ data }) => setTblData(data))
      .finally(() => setTblLoad(false))
      .catch((er) => {
        toast.error("Pengambilan data error");
        console.log(er);
      });
  };

  const submitEdit = () => {
    setEditLoad(true);
    Axios.put(
      `${auth.endPoint}it-inventory/mutasi-barang-reject/${editData._id}/update`,
      {
        api_token: auth.user.api_token,
        ...editData,
      }
    )
      .then(({ data }) => {
        setTblData(data);
        toggleMdEdit();
        toast.success("Data berhasil dirubah!");
      })
      .finally(() => setEditLoad(false))
      .catch((er) => {
        console.log(er);
        if (er.response) {
          if (er.response.status === 422) {
            toast.error(er.response.data);
          } else {
            toast.error("Server Error!");
          }
        }
      });
  };

  const deleteMBR = (dt) => {
    Swal.fire({
      title: "Hapus " + dt.kode_barang + " (" + dt.nama_barang + ")?",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      showLoaderOnConfirm: true,
      allowOutsideClick: !Swal.isLoading,
      icon: "question",
      preConfirm: () => {
        return Axios.delete(
          `${auth.endPoint}it-inventory/mutasi-barang-reject/${dt._id}?api_token=${auth.user.api_token}`
        )
          .then(() => {
            getData();
          })
          .catch((er) => {
            Swal.showValidationMessage("Error " + er.response.status);
          });
      },
    }).then((e) => {
      if (e.isConfirmed) {
        toast.success("Data berhasil dihapus!");
      }
    });
  };

  const cols = [
    {
      Header: "Act",
      accessor: "_id",
      width: 70,
      Cell: (c) => {
        return (
          <div className="d-flex justify-content-center w-100">
            <FontAwesomeIcon
              className="text-warning mx-1"
              style={{ cursor: "pointer" }}
              id={"b_edit" + c.value}
              icon={faPenAlt}
              onClick={() => {
                setEditData(c.original);
                setMdEdit(true);
              }}
            />
            <FontAwesomeIcon
              className="text-danger mx-1"
              style={{ cursor: "pointer" }}
              id={"b_delete" + c.value}
              icon={faTimesCircle}
              onClick={() => deleteMBR(c.original)}
            />
            <UncontrolledTooltip target={"b_edit" + c.value}>
              Edit
            </UncontrolledTooltip>
            <UncontrolledTooltip target={"b_delete" + c.value}>
              Hapus
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      Header: "No",
      id: "no",
      width: 40,
      Cell: (d) => d.index + 1,
    },
    {
      Header: "Kode Barang",
      accessor: "kode_barang",
      maxWidth: 125,
    },
    {
      Header: "Nama Barang",
      accessor: "nama_barang",
    },
    {
      Header: "Satuan",
      accessor: "satuan",
      maxWidth: 100,
    },
    {
      Header: "Saldo Awal",
      accessor: "saldo_awal",
      width: 100,
    },
    {
      Header: "Total Pemasukan",
      accessor: "total_pemasukan",
      width: 100,
    },
    {
      Header: "Total Pengeluaran",
      accessor: "total_pengeluaran",
      width: 100,
    },
    {
      Header: "Penyesuaian",
      accessor: "penyesuaian",
      width: 100,
    },
    {
      Header: "Saldo Akhir",
      accessor: "saldo_akhir",
      width: 100,
    },
  ];
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Button color="primary" disabled={tblLoad} onClick={getData}>
            Refresh
          </Button>
          <ReactTable
            filterable
            getTheadTrProps={() => {
              return {
                style: { fontSize: "9pt" },
              };
            }}
            getTdProps={() => {
              return {
                style: {
                  fontSize: "9pt",
                },
              };
            }}
            columns={cols}
            data={tblData}
            loading={tblLoad}
            className="-striped -highlight -fixed mt-3"
            style={{ height: winHeight }}
          />
        </CardBody>
      </Card>
      <Modal size="lg" toggle={toggleMdEdit} isOpen={mdEdit}>
        <ModalHeader toggle={toggleMdEdit}>Edit Pemasukan Barang</ModalHeader>
        {editData ? (
          <ModalBody>
            <Row>
              <FormGroup className="col">
                <Label>Kode Barang</Label>
                <Input
                  defaultValue={editData.kode_barang}
                  onChange={({ target }) =>
                    setEditData({ ...editData, kode_barang: target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="col">
                <Label>Nama Barang</Label>
                <Input
                  defaultValue={editData.nama_barang}
                  onChange={({ target }) =>
                    setEditData({ ...editData, nama_barang: target.value })
                  }
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col">
                <Label>Saldo Awal</Label>
                <Input
                  defaultValue={editData.saldo_awal}
                  onChange={({ target }) =>
                    setEditData({ ...editData, saldo_awal: target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="col">
                <Label>Total Pemasukan</Label>
                <Input
                  defaultValue={editData.total_pemasukan}
                  onChange={({ target }) =>
                    setEditData({ ...editData, total_pemasukan: target.value })
                  }
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col">
                <Label>Total Pengeluaran</Label>
                <Input
                  defaultValue={editData.total_pengeluaran}
                  onChange={({ target }) =>
                    setEditData({
                      ...editData,
                      total_pengeluaran: target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col">
                <Label>Penyesuaian</Label>
                <Input
                  defaultValue={editData.penyesuaian}
                  onChange={({ target }) =>
                    setEditData({ ...editData, penyesuaian: target.value })
                  }
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col">
                <Label>Saldo Akhir</Label>
                <Input
                  defaultValue={editData.saldo_akhir}
                  onChange={({ target }) =>
                    setEditData({ ...editData, saldo_akhir: target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="col">
                <Label>Tanggal</Label>
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  selected={new Date(editData.tanggal)}
                  selectsStart
                  className="form-control"
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      tanggal: moment(e).format("YYYY-MM-DD"),
                    })
                  }
                />
              </FormGroup>
            </Row>
          </ModalBody>
        ) : (
          ""
        )}
        <ModalFooter>
          <Button
            color="success"
            onClick={submitEdit}
            disabled={editLoad || !editData}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
