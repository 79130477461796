import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../PageTitle";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import ReactTable from "react-table";
import { useSelector } from "react-redux";
import Select from "react-select";
import Axios from "../../../Axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faICursor,
  faKey,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function MasterUser() {
  const [tblData, setTblData] = useState([]);
  const [tblLoad, setTblLoad] = useState(false);
  const [mdAdd, setMdAdd] = useState(false);
  const [addData, setAddData] = useState({});
  const [roles, setRoles] = useState([]);
  const [editData, setEditData] = useState({});
  const [mdEdit, setMdEdit] = useState(false);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    Axios.get(`${auth.endPoint}admin/roles?api_token=${auth.user.api_token}`)
      .then(({ data }) => {
        setRoles(
          data.map((d) => {
            return { label: d.description, value: d.level };
          })
        );
      })
      .catch(({ response }) => {
        toast.error("Gagal mengambil data role");
        console.log(response);
      });
    setTblLoad(true);
    Axios.get(`${auth.endPoint}admin/users?api_token=${auth.user.api_token}`)
      .then(({ data }) => setTblData(data))
      .finally(() => setTblLoad(false))
      .catch(({ response }) => {
        toast.error("Gagal mengambil data");
        console.log(response);
      });
  }, [auth.endPoint, auth.user.api_token]);
  const getData = () => {
    setTblLoad(true);
    Axios.get(`${auth.endPoint}admin/users?api_token=${auth.user.api_token}`)
      .then(({ data }) => setTblData(data))
      .finally(() => setTblLoad(false))
      .catch(({ response }) => {
        toast.error("Gagal mengambil data");
        console.log(response);
      });
  };

  const toggleMdAdd = () => {
    setAddData({ roleSelected: roles[0], role_level: roles[0].value });
    setMdAdd(!mdAdd);
  };
  const toggleMdEdit = () => {
    setMdEdit(!mdEdit);
    setEditData({});
  };
  const editUser = (dt) => {
    setEditData({
      id: dt._id,
      name: dt.name,
      selectedRole: { label: dt.role.description, value: dt.role_level },
      role_level: dt.role_level,
      email: dt.email,
    });
    setMdEdit(true);
  };
  const resetPassword = (d) => {
    Swal.fire({
      title: "Masukan Password Baru",
      input: "password",
      showCancelButton: true,
      confirmButtonText: "Ganti Password",
      preConfirm: (pass) => {
        return Axios.put(`${auth.endPoint}admin/users/${d._id}`, {
          api_token: auth.user.api_token,
          password: pass,
        })
          .then((res) => {
            let publicIp = "";
            Axios.get("https://api.ipify.org/?format=json")
              .then(({ data }) => (publicIp = data.ip))
              .finally(() => {
                Axios.post(`${auth.endPoint}log`, {
                  api_token: auth.user.api_token,
                  origin: publicIp,
                  category: "USER MODIFY",
                  activity: "Merubah password " + d.name,
                });
              });
            return res;
          })
          .catch(() =>
            Swal.showValidationMessage("Error! silahkan coba lagi.")
          );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((res) => {
      if (res.isConfirmed) {
        toast.success("Password berhasil di rubah!");
      }
    });
  };

  const columns = [
    {
      Header: () => <FontAwesomeIcon icon={faICursor} />,
      accessor: "_id",
      sortable: false,
      filterable: false,
      maxWidth: 100,
      Cell: (e) => (
        <div className="d-flex justify-content-center w-100">
          <FontAwesomeIcon
            icon={faKey}
            style={{ cursor: "pointer" }}
            className="text-primary mx-2"
            id={"reset" + e.value}
            onClick={() => resetPassword(e.original)}
          />
          <FontAwesomeIcon
            icon={faPencilAlt}
            style={{ cursor: "pointer" }}
            className="text-warning mx-2"
            id={"edit" + e.value}
            onClick={() => editUser(e.original)}
          />
          <FontAwesomeIcon
            icon={faTrash}
            style={{ cursor: "pointer" }}
            className="text-danger mx-2"
            onClick={() => deleteUser(e.original)}
            id={"delete" + e.value}
          />
          <UncontrolledTooltip target={"edit" + e.value}>
            Edit
          </UncontrolledTooltip>
          <UncontrolledTooltip target={"delete" + e.value}>
            Delete
          </UncontrolledTooltip>
          <UncontrolledTooltip target={"reset" + e.value}>
            Reset Password
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      Header: "Nama",
      accessor: "name",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Role",
      accessor: "role.description",
    },
    {
      Header: "EMail",
      accessor: "email",
    },
    {
      Header: "Terakhir Aktif",
      accessor: "lastseen",
    },
  ];
  const deleteUser = (d) => {
    Swal.fire({
      title: "Hapus?",
      text: d.name,
      showCancelButton: true,
      confirmButtonText: "Hapus",
    }).then((e) => {
      if (e.isConfirmed) {
        Axios.delete(
          `${auth.endPoint}admin/users/${d._id}?api_token=${auth.user.api_token}`
        )
          .then(() => {
            let publicIp = "";
            Axios.get("https://api.ipify.org/?format=json")
              .then(({ data }) => (publicIp = data.ip))
              .finally(() => {
                Axios.post(`${auth.endPoint}log`, {
                  api_token: auth.user.api_token,
                  origin: publicIp,
                  category: "USER DELETE",
                  activity: "Menghapus User " + d.name,
                });
              });
            toast(d.name + " dihapus!");
            getData();
          })
          .catch(() => toast("Gagal menghapus"));
      }
    });
  };
  const storeUser = (e) => {
    e.preventDefault();
    Axios.post(`${auth.endPoint}admin/users/store`, {
      api_token: auth.user.api_token,
      ...addData,
    })
      .then(() => {
        let publicIp = "";
        Axios.get("https://api.ipify.org/?format=json")
          .then(({ data }) => (publicIp = data.ip))
          .finally(() => {
            Axios.post(`${auth.endPoint}log`, {
              api_token: auth.user.api_token,
              origin: publicIp,
              category: "USER ADD",
              activity: "Menambahkan user " + addData.name,
            });
          });
        toast.success("User berhasil ditambahkan!");
        getData();
        toggleMdAdd();
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          toast.error(response.data);
          if (response.data === "Username sudah ada!") {
            setAddData({ ...addData, username: null });
            let a = document.getElementById("add_username");
            a.value = "";
            a.focus();
          }
        } else {
          toast.error("Gagal menambahkan user");
        }
      });
  };
  const submitEdit = (e) => {
    e.preventDefault();
    Axios.put(`${auth.endPoint}admin/users/${editData.id}`, {
      api_token: auth.user.api_token,
      ...editData,
    })
      .then(() => {
        let publicIp = "";
        Axios.get("https://api.ipify.org/?format=json")
          .then(({ data }) => (publicIp = data.ip))
          .finally(() => {
            Axios.post(`${auth.endPoint}log`, {
              api_token: auth.user.api_token,
              origin: publicIp,
              category: "USER MODIFY",
              activity: "Merubah user " + editData.name,
            });
          });
        toast.success("User berhasil dirubah!");
        getData();
        toggleMdEdit();
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          toast.error(response.data);
          if (response.data === "Username sudah ada!") {
            setAddData({ ...editData, username: null });
            let a = document.getElementById("edit_username");
            a.value = "";
            a.focus();
          }
        } else {
          toast.error("Gagal merubah user");
        }
      });
  };
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="User Log Activity"
          subheading="Log Aktivitas semua user"
          icon="pe-7s-timer icon-gradient bg-malibu-beach"
        />
        <Card>
          <CardBody>
            <div className="d-flex justify-content-start my-3">
              <Button color="success" onClick={toggleMdAdd}>
                <FontAwesomeIcon icon={faPlus} /> Tambah User
              </Button>
            </div>
            <ReactTable
              filterable
              data={tblData}
              columns={columns}
              loading={tblLoad}
              defaultPageSize="10"
            />
          </CardBody>
        </Card>
        <Modal isOpen={mdAdd} toggle={toggleMdAdd}>
          <ModalHeader toggle={toggleMdAdd}>Tambah User Baru</ModalHeader>
          <ModalBody>
            <Form id="fadd" onSubmit={storeUser}>
              <FormGroup>
                <Label>Nama</Label>
                <Input
                  onChange={({ target }) =>
                    setAddData({ ...addData, nama: target.value })
                  }
                  placeholder="Nama User..."
                  invalid={!addData.nama}
                  valid={!!addData.nama}
                />
                <FormFeedback>Tidak Boleh Kosong!</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Username</Label>
                <Input
                  value={addData.username}
                  id="add_username"
                  onChange={({ target }) =>
                    setAddData({ ...addData, username: target.value })
                  }
                  placeholder="Username..."
                  invalid={!addData.username || addData.username.length < 3}
                  valid={!!addData.username}
                />
                <FormFeedback>
                  Minimal 3 karakter, Tidak Boleh Kosong!
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type="password"
                  onChange={({ target }) =>
                    setAddData({ ...addData, password: target.value })
                  }
                  placeholder="Password..."
                  invalid={!addData.password || addData.password.length < 6}
                  valid={!!addData.password}
                />
                <FormFeedback>
                  Minimal 6 karakter, Tidak Boleh Kosong!
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Konfirmasi Password</Label>
                <Input
                  type="password"
                  onChange={({ target }) =>
                    setAddData({ ...addData, password_confirm: target.value })
                  }
                  placeholder="Ketik Ulang Password..."
                  invalid={addData.password !== addData.password_confirm}
                />
                {addData.password !== addData.password_confirm ? (
                  <FormFeedback className="text-danger">
                    Password tidak sama!
                  </FormFeedback>
                ) : (
                  ""
                )}
              </FormGroup>
              <FormGroup>
                <Label>Role</Label>
                <Select
                  options={roles}
                  onChange={(e) =>
                    setAddData({
                      ...addData,
                      roleSelected: e,
                      role_level: e.value,
                    })
                  }
                  value={addData.roleSelected}
                />
              </FormGroup>
              <Button
                color="success"
                type="submit"
                form="fadd"
                disabled={
                  !addData.nama ||
                  !addData.username ||
                  !addData.password ||
                  addData.password !== addData.password_confirm
                }
              >
                Submit
              </Button>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={mdEdit} toggle={toggleMdEdit}>
          <ModalHeader toggle={toggleMdEdit}>Tambah User Baru</ModalHeader>
          <ModalBody>
            <Form id="fedit" onSubmit={submitEdit}>
              <FormGroup>
                <Label>Nama</Label>
                <Input
                  defaultValue={editData.name}
                  onChange={({ target }) =>
                    setEditData({ ...editData, name: target.value })
                  }
                  placeholder="Nama User..."
                  invalid={!editData.name}
                  valid={!!editData.name}
                />
                <FormFeedback>Tidak Boleh Kosong!</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Role</Label>
                <Select
                  options={roles}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      selectedRole: e,
                      role_level: e.value,
                    })
                  }
                  value={editData.selectedRole}
                />
              </FormGroup>
              <Button
                color="success"
                type="submit"
                form="fedit"
                disabled={!editData.name || !editData.role_level}
              >
                Submit
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
}
