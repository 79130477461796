import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../PageTitle";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Col,
  FormGroup,
  Row,
  Label,
  UncontrolledTooltip,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactTable from "react-table";
import { useSelector } from "react-redux";
import Axios from "../../../../Axios";
import { toast } from "react-toastify";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

export default function EximNote() {
  const [tblData, setTblData] = useState([]);
  const [tblLoad, setTblLoad] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [addData, setAddData] = useState({});
  const [addLoad, setAddLoad] = useState(false);
  const [edtData, setEdtData] = useState();
  const [edtLoad, setEdtLoad] = useState(false);
  const [mdEdit, setMdEdit] = useState(false);

  const toggleMdEdit = () => {
    setMdEdit(false);
    setEdtData();
  };

  useEffect(() => {
    setTblLoad(true);
    Axios.get(
      `${auth.endPoint}it-inventory/notes?api_token=${auth.user.api_token}`
    )
      .then(({ data }) => setTblData(data))
      .finally(() => setTblLoad(false))
      .catch((er) => {
        console.log(er);
        toast.error("Gagal memuat data");
      });
  }, [auth.endPoint, auth.user.api_token]);

  const cols = [
    {
      Header: "Act",
      accessor: "_id",
      filterable: false,
      sortable: false,
      maxWidth: 80,
      Cell: (c) => (
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => {
              setEdtData(c.original);
              setMdEdit(true);
            }}
            className="ml-2 text-warning"
            style={{ cursor: "pointer" }}
            id={"edt-" + c.index}
          />
          <FontAwesomeIcon
            icon={faTrash}
            className="ml-2 text-danger"
            style={{ cursor: "pointer" }}
            id={"del-" + c.index}
            onClick={() => deleteRow(c.original)}
          />
          <UncontrolledTooltip target={"edt-" + c.index}>
            Edit
          </UncontrolledTooltip>
          <UncontrolledTooltip target={"del-" + c.index}>
            Hapus
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      Header: "No",
      id: "no",
      filterable: false,
      maxWidth: 50,
      Cell: (c) => c.index + 1,
    },
    {
      Header: "Dokumen Pabean",
      columns: [
        {
          Header: "Jenis BC",
          accessor: "jenis_doc_dp",
        },
        {
          Header: "No BC",
          accessor: "no_bc_dp",
        },
        {
          Header: "Tgl BC",
          accessor: "tgl_bc_dp",
        },
      ],
    },
    {
      Header: "Kesalahan Input",
      columns: [
        {
          Header: "Jenis BC",
          accessor: "jenis_doc_k",
        },
        {
          Header: "No BC",
          accessor: "no_bc_k",
        },
        {
          Header: "Tgl BC",
          accessor: "tgl_bc_k",
        },
      ],
    },
    {
      Header: "Keterangan",
      accessor: "keterangan",
    },
    {
      Header: "Diinput Oleh",
      accessor: "input",
    },
    {
      Header: "Diinput Tgl",
      accessor: "tgl_input",
    },
  ];
  const submitAdd = () => {
    setAddLoad(true);
    Axios.post(`${auth.endPoint}it-inventory/notes`, {
      api_token: auth.user.api_token,
      ...addData,
    })
      .then(({ data }) => {
        setTblData(data);
        toast.success("Catatan berhasil ditambahkan");
      })
      .finally(() => setAddLoad(false))
      .catch((er) => {
        console.log(er);
        if (er.response.status === 422) {
          toast.error(er.response.data);
        } else {
          toast.error("Server Error!");
        }
      });
  };
  const deleteRow = (dt) => {
    Swal.fire({
      title: "Hapus Catatan?",
      showCancelButton: true,
      confirmButtonText: "Hapus!",
      icon: "question",
      allowOutsideClick: !Swal.isLoading,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        setTblLoad(true);
        return Axios.delete(
          `${auth.endPoint}it-inventory/notes/${dt._id}/delete?api_token=${auth.user.api_token}`
        )
          .then(({ data }) => setTblData(data))
          .finally(() => setTblLoad(false))
          .catch((er) =>
            Swal.showValidationMessage("Error menghapus catatan!")
          );
      },
    }).then((e) => {
      if (e.isConfirmed) {
        toast.success("Catatan Dihapus!");
      }
    });
  };
  const submitEdit = () => {
    setEdtLoad(true);
    Axios.put(`${auth.endPoint}it-inventory/notes/${edtData._id}/update`, {
      api_token: auth.user.api_token,
      ...edtData,
    })
      .then(({ data }) => {
        setTblData(data);
        toast.success("Catatan berhasil dirubah");
        toggleMdEdit();
      })
      .finally(() => setEdtLoad(false))
      .catch((er) => {
        console.log(er);
        if (er.response.status === 422) {
          toast.error(er.response.data);
        } else {
          toast.error("Server Error!");
        }
      });
  };
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Catatan Exim"
          subheading="Catatan Kesalahan Input"
          icon="pe-7s-note icon-gradient bg-malibu-beach"
        />
        <Card className="mb-3">
          <CardBody>
            <CardTitle>Tambah Catatan</CardTitle>
            <Form id="add">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Dokumen Pabean</Label>
                    <Input
                      onChange={(e) =>
                        setAddData({ ...addData, jenis_doc_dp: e.target.value })
                      }
                      onFocus={(e) => e.target.select()}
                      className="mb-1"
                      placeholder="Jenis BC"
                      size="sm"
                    />
                    <Input
                      onFocus={(e) => e.target.select()}
                      onChange={(e) =>
                        setAddData({ ...addData, no_bc_dp: e.target.value })
                      }
                      className="mb-1"
                      placeholder="No BC"
                      size="sm"
                    />
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      selected={
                        addData.tgl_bc_dp ? new Date(addData.tgl_bc_dp) : ""
                      }
                      size="sm"
                      placeholderText="Tgl BC"
                      className="form-control form-control-sm"
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          tgl_bc_dp: moment(e).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Kesalahan Input</Label>
                    <Input
                      onFocus={(e) => e.target.select()}
                      onChange={(e) =>
                        setAddData({ ...addData, jenis_doc_k: e.target.value })
                      }
                      className="mb-1"
                      placeholder="Jenis BC"
                      size="sm"
                    />
                    <Input
                      onFocus={(e) => e.target.select()}
                      onChange={(e) =>
                        setAddData({ ...addData, no_bc_k: e.target.value })
                      }
                      className="mb-1"
                      placeholder="No BC"
                      size="sm"
                    />
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      selected={
                        addData.tgl_bc_k ? new Date(addData.tgl_bc_k) : ""
                      }
                      placeholderText="Tgl BC"
                      className="form-control form-control-sm"
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          tgl_bc_k: moment(e).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Keterangan</Label>
                    <textarea
                      className="form-control"
                      placeholder="Keterangan"
                      rows="3"
                      onChange={(e) =>
                        setAddData({ ...addData, keterangan: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Input Oleh</Label>
                    <Input
                      onChange={(e) =>
                        setAddData({ ...addData, input: e.target.value })
                      }
                      placeholder="Input Oleh"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    disabled={
                      addLoad ||
                      !addData.jenis_doc_dp ||
                      !addData.no_bc_dp ||
                      !addData.tgl_bc_dp ||
                      !addData.input ||
                      !addData.keterangan
                    }
                    color="success"
                    block
                    size="lg"
                    onClick={submitAdd}
                  >
                    {addLoad ? <Spinner /> : "Simpan"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>

        <Card>
          <CardBody className="p-0">
            <ReactTable
              filterable
              data={tblData}
              columns={cols}
              loading={tblLoad}
              defaultPageSize="10"
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
      <Modal size="lg" toggle={toggleMdEdit} isOpen={mdEdit}>
        <ModalHeader toggle={toggleMdEdit}>Edit Catatan</ModalHeader>
        {edtData ? (
          <ModalBody>
            <Form id="edt">
              <FormGroup>
                <Label>Dokumen Pabean</Label>
                <Input
                  defaultValue={edtData.jenis_doc_dp}
                  onChange={(e) =>
                    setEdtData({ ...edtData, jenis_doc_dp: e.target.value })
                  }
                  onFocus={(e) => e.target.select()}
                  className="mb-1"
                  placeholder="Jenis BC"
                  size="sm"
                />
                <Input
                  defaultValue={edtData.no_bc_dp}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    setEdtData({ ...edtData, no_bc_dp: e.target.value })
                  }
                  className="mb-1"
                  placeholder="No BC"
                  size="sm"
                />
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  selected={
                    edtData.tgl_bc_dp ? new Date(edtData.tgl_bc_dp) : ""
                  }
                  size="sm"
                  placeholderText="Tgl BC"
                  className="form-control form-control-sm"
                  onChange={(e) =>
                    setEdtData({
                      ...edtData,
                      tgl_bc_dp: moment(e).format("YYYY-MM-DD"),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Kesalahan Input</Label>
                <Input
                  defaultValue={edtData.jenis_doc_k}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    setEdtData({ ...edtData, jenis_doc_k: e.target.value })
                  }
                  className="mb-1"
                  placeholder="Jenis BC"
                  size="sm"
                />
                <Input
                  defaultValue={edtData.no_bc_k}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    setEdtData({ ...edtData, no_bc_k: e.target.value })
                  }
                  className="mb-1"
                  placeholder="No BC"
                  size="sm"
                />
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  selected={edtData.tgl_bc_k ? new Date(edtData.tgl_bc_k) : ""}
                  placeholderText="Tgl BC"
                  className="form-control form-control-sm"
                  onChange={(e) =>
                    setEdtData({
                      ...edtData,
                      tgl_bc_k: moment(e).format("YYYY-MM-DD"),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Keterangan</Label>
                <textarea
                  defaultValue={edtData.keterangan}
                  className="form-control"
                  placeholder="Keterangan"
                  rows="3"
                  onChange={(e) =>
                    setEdtData({ ...edtData, keterangan: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Input Oleh</Label>
                <Input
                  defaultValue={edtData.input}
                  onChange={(e) =>
                    setEdtData({ ...edtData, input: e.target.value })
                  }
                  placeholder="Input Oleh"
                />
              </FormGroup>
              <Button
                disabled={
                  edtLoad ||
                  !edtData.jenis_doc_dp ||
                  !edtData.no_bc_dp ||
                  !edtData.tgl_bc_dp ||
                  !edtData.input ||
                  !edtData.keterangan
                }
                onClick={submitEdit}
                color="success"
                block
                size="lg"
              >
                {addLoad ? <Spinner /> : "Simpan"}
              </Button>
            </Form>
          </ModalBody>
        ) : (
          <ModalBody />
        )}
      </Modal>
    </Fragment>
  );
}
