import Axios from "../../../Axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Card, CardHeader, Col, Container, Progress, Row } from "reactstrap";
import CountUp from "react-countup";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function DPemasukan({ formatNumber, dari, sampai, loader }) {
  const auth = useSelector((state) => state.auth);
  const [pemasukan, setPemasukan] = useState([]);
  const [pemasukanLoad, setPemasukanLoad] = useState(false);
  const [totalBcPemasukan, setTotalBcPemasukan] = useState(0);
  const [bc4In, setBc4In] = useState();
  const [bc23, setBc23] = useState();
  const [bc262, setBc262] = useState();
  const [bc27, setBc27] = useState();
  const [bc30, setBc30] = useState();
  const [bcnull, setBcnull] = useState();

  useEffect(() => {
    const dari = moment().startOf("year");
    const dari_bulan = moment().startOf("month");
    const sampai = moment();
    setPemasukanLoad(true);
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-barang", {
      api_token: auth.user.api_token,
      tgl_dari: dari.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
    })
      .then(({ data }) => {
        setPemasukan(data.data);
        setTotalBcPemasukan(data.total);
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => setPemasukanLoad(false));

    //bc4.0
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-perbc", {
      api_token: auth.user.api_token,
      tgl_dari: dari_bulan.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
      bc: "BC 4.0",
    })
      .then(({ data }) => setBc4In(data))
      .catch((er) => "");
    //bc 2.3
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-perbc", {
      api_token: auth.user.api_token,
      tgl_dari: dari_bulan.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
      bc: "BC 2.3",
    })
      .then(({ data }) => setBc23(data))
      .catch((er) => "");
    //bc 2.6.2
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-perbc", {
      api_token: auth.user.api_token,
      tgl_dari: dari_bulan.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
      bc: "BC 2.6.2",
    })
      .then(({ data }) => setBc262(data))
      .catch((er) => "");
    //bc 2.7
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-perbc", {
      api_token: auth.user.api_token,
      tgl_dari: dari_bulan.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
      bc: "BC 2.7",
    })
      .then(({ data }) => setBc27(data))
      .catch((er) => "");
    //bc 3.0
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-perbc", {
      api_token: auth.user.api_token,
      tgl_dari: dari_bulan.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
      bc: "BC 3.0",
    })
      .then(({ data }) => setBc30(data))
      .catch((er) => "");
    //bc null
    Axios.post(auth.endPoint + "it-inventory/dashboard/pemasukan-perbc", {
      api_token: auth.user.api_token,
      tgl_dari: dari_bulan.format("YYYY-MM-DD"),
      tgl_sampai: sampai.format("YYYY-MM-DD"),
      bc: "null",
    })
      .then(({ data }) => setBcnull(data))
      .catch((er) => "");
  }, [auth.endPoint, auth.user.api_token]);

  return (
    <Container fluid>
      <h5 className="text-primary mt-3 mb-3">
        BC Tanggal{" "}
        {dari.format("DD MMM YYYY") + " ~ " + sampai.format("DD MMM YYYY")}
      </h5>
      <Row className="mb-4">
        {!pemasukanLoad
          ? pemasukan.length
            ? pemasukan.map((m, i) => {
                let ret = "";
                if (m.bc === "JENIS BC TIDAK DIINPUT") {
                  if (auth.user.role_level > 1) {
                    ret = (
                      <Col key={i} md="6" xl="4">
                        <div className="card mb-3 widget-content">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="widget-heading">{m.bc}</div>
                                <div className="widget-subheading">
                                  {dari.format("DD-MMM")} ~{" "}
                                  {sampai.format("DD-MMM")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-success">
                                  <CountUp
                                    useEasing
                                    formattingFn={(n) => formatNumber(n)}
                                    duration={3}
                                    end={m.count}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="widget-progress-wrapper">
                              <Progress
                                className="progress-bar-sm"
                                color="primary"
                                value={Math.round(
                                  (m.count / totalBcPemasukan) * 100
                                )}
                              />
                              <div className="progress-sub-label">
                                <div className="sub-label-left">Persentase</div>
                                <div className="sub-label-right">
                                  {Math.round(
                                    ((m.count / totalBcPemasukan) * 100 +
                                      Number.EPSILON) *
                                      100
                                  ) / 100}
                                  %
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  }
                } else {
                  ret = (
                    <Col key={i} md="6" xl="4">
                      <div className="card mb-3 widget-content">
                        <div className="widget-content-outer">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                              <div className="widget-heading">{m.bc}</div>
                              <div className="widget-subheading">
                                {dari.format("DD-MMM")} ~{" "}
                                {sampai.format("DD-MMM")}
                              </div>
                            </div>
                            <div className="widget-content-right">
                              <div className="widget-numbers text-success">
                                <CountUp
                                  useEasing
                                  formattingFn={(n) => formatNumber(n)}
                                  duration={3}
                                  end={m.count}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="widget-progress-wrapper">
                            <Progress
                              className="progress-bar-sm"
                              color="primary"
                              value={Math.round(
                                (m.count / totalBcPemasukan) * 100
                              )}
                            />
                            <div className="progress-sub-label">
                              <div className="sub-label-left">Persentase</div>
                              <div className="sub-label-right">
                                {Math.round(
                                  ((m.count / totalBcPemasukan) * 100 +
                                    Number.EPSILON) *
                                    100
                                ) / 100}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }
                return ret;
              })
            : ""
          : loader}
      </Row>
      <h5 className="mb-3 mt-3 text-primary">
        Ringkasan BC Bulan {sampai.format("MMMM")}
      </h5>
      <Row>
        {bc4In ? (
          <Col md="6" lg="6" sm="12">
            <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
              <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                <div>
                  <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                    BC 4.0
                  </h5>
                  <h6 className="widget-subheading mb-0 opacity-5">
                    1 - {sampai.format("DD MMM YYYY")}
                  </h6>
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pr-3 pl-5">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="text-primary">
                            <CountUp
                              start={0}
                              end={bc4In.total}
                              separator=""
                              decimals={0}
                              decimal=","
                              prefix=""
                              duration="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                    <ResponsiveContainer height={114}>
                      <AreaChart
                        data={bc4In.data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="tgl" />
                        <YAxis dataKey="jml" />
                        <Tooltip />
                        <Area
                          type="monotoneX"
                          dataKey="jml"
                          stroke="var(--primary)"
                          strokeOpacity=".8"
                          strokeWidth={3}
                          fill="var(--primary)"
                          fillOpacity=".08"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ) : (
          loader
        )}
        {bc23 ? (
          <Col md="6" lg="6" sm="12">
            <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
              <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                <div>
                  <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                    BC 2.3
                  </h5>
                  <h6 className="widget-subheading mb-0 opacity-5">
                    1 - {sampai.format("DD MMM YYYY")}
                  </h6>
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pr-3 pl-5">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="text-primary">
                            <CountUp
                              start={0}
                              end={bc23.total}
                              separator=""
                              decimals={0}
                              decimal=","
                              prefix=""
                              duration="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                    <ResponsiveContainer height={114}>
                      <AreaChart
                        data={bc23.data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="tgl" />
                        <YAxis dataKey="jml" />
                        <Tooltip />
                        <Area
                          type="monotoneX"
                          dataKey="jml"
                          stroke="var(--primary)"
                          strokeOpacity=".8"
                          strokeWidth={3}
                          fill="var(--primary)"
                          fillOpacity=".08"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ) : (
          loader
        )}
        {bc262 ? (
          <Col md="6" lg="6" sm="12">
            <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
              <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                <div>
                  <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                    BC 2.6.2
                  </h5>
                  <h6 className="widget-subheading mb-0 opacity-5">
                    1 - {sampai.format("DD MMM YYYY")}
                  </h6>
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pr-3 pl-5">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="text-primary">
                            <CountUp
                              start={0}
                              end={bc262.total}
                              separator=""
                              decimals={0}
                              decimal=","
                              prefix=""
                              duration="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                    <ResponsiveContainer height={114}>
                      <AreaChart
                        data={bc262.data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="tgl" />
                        <YAxis dataKey="jml" />
                        <Tooltip />
                        <Area
                          type="monotoneX"
                          dataKey="jml"
                          stroke="var(--primary)"
                          strokeOpacity=".8"
                          strokeWidth={3}
                          fill="var(--primary)"
                          fillOpacity=".08"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ) : (
          loader
        )}
        {bc27 ? (
          <Col md="6" lg="6" sm="12">
            <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
              <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                <div>
                  <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                    BC 2.7
                  </h5>
                  <h6 className="widget-subheading mb-0 opacity-5">
                    1 - {sampai.format("DD MMM YYYY")}
                  </h6>
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pr-3 pl-5">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="text-primary">
                            <CountUp
                              start={0}
                              end={bc27.total}
                              separator=""
                              decimals={0}
                              decimal=","
                              prefix=""
                              duration="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                    <ResponsiveContainer height={114}>
                      <AreaChart
                        data={bc27.data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="tgl" />
                        <YAxis dataKey="jml" />
                        <Tooltip />
                        <Area
                          type="monotoneX"
                          dataKey="jml"
                          stroke="var(--primary)"
                          strokeOpacity=".8"
                          strokeWidth={3}
                          fill="var(--primary)"
                          fillOpacity=".08"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ) : (
          loader
        )}
        {bc30 ? (
          <Col md="6" lg="6" sm="12">
            <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
              <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                <div>
                  <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                    BC 3.0
                  </h5>
                  <h6 className="widget-subheading mb-0 opacity-5">
                    1 - {sampai.format("DD MMM YYYY")}
                  </h6>
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pr-3 pl-5">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="text-primary">
                            <CountUp
                              start={0}
                              end={bc30.total}
                              separator=""
                              decimals={0}
                              decimal=","
                              prefix=""
                              duration="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                    <ResponsiveContainer height={114}>
                      <AreaChart
                        data={bc30.data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="tgl" />
                        <YAxis dataKey="jml" />
                        <Tooltip />
                        <Area
                          type="monotoneX"
                          dataKey="jml"
                          stroke="var(--primary)"
                          strokeOpacity=".8"
                          strokeWidth={3}
                          fill="var(--primary)"
                          fillOpacity=".08"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ) : (
          loader
        )}
        {bcnull ? (
          auth.user.role_level > 1 ? (
            <Col md="6" lg="6" sm="12">
              <Card className="card-hover-shadow-2x mb-3 card-btm-border card-shadow-primary border-primary">
                <CardHeader className="rm-border pb-0 mt-sm-3 responsive-center">
                  <div>
                    <h5 className="menu-header-title text-capitalize fsize-2 text-muted text-left opacity-6">
                      JENIS BC TIDAK DIINPUT
                    </h5>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      1 - {sampai.format("DD MMM YYYY")}
                    </h6>
                  </div>
                </CardHeader>
                <div className="widget-chart widget-chart2 text-left p-0">
                  <div className="widget-chat-wrapper-outer">
                    <div className="widget-chart-content pt-3 pr-3 pl-5">
                      <div className="widget-chart-flex">
                        <div className="widget-numbers">
                          <div className="widget-chart-flex">
                            <div className="text-primary">
                              <CountUp
                                start={0}
                                end={bcnull.total}
                                separator=""
                                decimals={0}
                                decimal=","
                                prefix=""
                                duration="5"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-3 m-0 p-1">
                      <ResponsiveContainer height={114}>
                        <AreaChart
                          data={bcnull.data}
                          margin={{
                            top: 1,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <XAxis dataKey="tgl" />
                          <YAxis dataKey="jml" />
                          <Tooltip />
                          <Area
                            type="monotoneX"
                            dataKey="jml"
                            stroke="var(--primary)"
                            strokeOpacity="1"
                            strokeWidth={3}
                            fill="var(--primary)"
                            fillOpacity=".08"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          ) : (
            ""
          )
        ) : (
          loader
        )}
      </Row>
    </Container>
  );
}
