import React, { useState } from "react";
import PageTitle from "../PageTitle";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import Switch from "react-switch";
import Axios from "../../../Axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ChangePassword() {
  const [vis, setVis] = useState(false);
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confPass, setConfPass] = useState();
  const [formLoad, setFormLoad] = useState(false);

  const dispatch = useDispatch();
  const hist = useHistory();

  const auth = useSelector((state) => state.auth);

  const submitPass = (e) => {
    e.preventDefault();
    setFormLoad(true);
    Axios.post(`${auth.endPoint}change-password`, {
      api_token: auth.user.api_token,
      oldPass,
      newPass,
    })
      .then(() => {
        toast.success("Password Berhasil Diganti!", {
          pauseOnHover: false,
          onClose: () => {
            hist.push("/login");
            dispatch({ type: "AUTH_LOGOUT" });
          },
        });
      })
      .finally(() => setFormLoad(false))
      .catch(({ response }) => {
        if (response.status === 422) {
          toast.error(response.data);
        } else {
          toast.error("Error Mengganti Password");
        }
      });
  };
  return (
    <div>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Change Password"
          subheading="Ganti Password Anda"
          icon="pe-7s-door-lock icon-gradient bg-dark"
        />
        <Card className="mb-3">
          <CardBody>
            <CardTitle>Ganti Password</CardTitle>
            <Form id="passForm" onSubmit={submitPass}>
              <FormGroup>
                <Label>Password Lama</Label>
                <Input
                  type={vis ? "text" : "password"}
                  defaultValue={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password Baru</Label>
                <Input
                  type={vis ? "text" : "password"}
                  defaultValue={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Ketik Ulang Password Baru</Label>
                <Input
                  type={vis ? "text" : "password"}
                  defaultValue={confPass}
                  onChange={(e) => setConfPass(e.target.value)}
                />
                {newPass !== confPass ? (
                  <FormText color="danger">
                    Ketik ulang password yang sama dengan password baru!
                  </FormText>
                ) : (
                  ""
                )}
              </FormGroup>
            </Form>
            <FormGroup>
              <Label>Perlihatkan Password</Label>
              <br />
              <Switch
                height={20}
                width={40}
                checked={vis}
                onChange={() => setVis(!vis)}
              />
            </FormGroup>
            <FormGroup>
              <Button
                type="submit"
                color="success"
                form="passForm"
                size="lg"
                disabled={
                  !oldPass ||
                  !newPass ||
                  !confPass ||
                  confPass !== newPass ||
                  formLoad
                }
              >
                Submit
              </Button>
            </FormGroup>
            <FormText>
              <i className="text-muted">
                Setelah password berhasil diganti, anda akan logout otomatis.
                Setelah itu silahkan login kembali dengan password baru
              </i>
            </FormText>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </div>
  );
}
