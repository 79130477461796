import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from "react-loaders";

export default function Login() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [endPoint, setEndPoint] = useState(auth.endPoint);
  const hist = useHistory();
  const [submitLoad, setSubmitLoad] = useState(false);

  const submitLogin = (e) => {
    e.preventDefault();
    if (username !== "" || password !== "") {
      setSubmitLoad(true);
      Axios.post(endPoint + "login", {
        username,
        password,
      })
        .then((user) => {
          let publicIp = "";
          Axios.get("https://api.ipify.org/?format=json")
            .then(({ data }) => (publicIp = data.ip))
            .finally(() => {
              Axios.post(`${auth.endPoint}log`, {
                api_token: user.data.api_token,
                origin: publicIp,
                category: "LOGIN",
                activity: "Melakukan Login",
              });
            });
          dispatch({ type: "AUTH_LOGIN", user: user.data });
          hist.push("/dashboard");
        })
        .catch((er) => {
          if (er.response.status === 422) {
            Swal.fire("Error!", er.response.data.message, "error");
          } else {
            Swal.fire("Error!", "Something went wrong...", "error");
          }
        })
        .finally(() => setSubmitLoad(false));
      // let dummyUser = {
      //   name: "Dummy User",
      //   role: "Dummy Role",
      // };
      // dispatch({ type: "AUTH_LOGIN", user: dummyUser });
    } else {
      Swal.fire("Error!", "Isi Username & Password!", "warning");
    }
  };

  const changeEndPoint = async () => {
    const { value: url } = await Swal.fire({
      input: "url",
      inputLabel: "EndPoint URL",
      inputPlaceholder: "Enter URL Here...",
      inputValue: endPoint,
    });
    if (url) {
      setEndPoint(url);
      dispatch({ type: "AUTH_ENDPOINT", ep: url });
      Swal.fire("Success!", "EndPoint Changed!", "success");
    }
  };

  const logo = require("../../assets/utils/images/webin_wide_inverse.png");

  return (
    <div className="h-100 bg-malibu-beach bg-animation">
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="8" className="mx-auto app-login-box">
          <img
            src={logo}
            alt="Logo"
            className="img-responsive mx-auto d-block mb-3"
            style={{ maxHeight: "140px" }}
          />
          <div className="modal-dialog w-100 mx-auto">
            <div className="modal-content">
              <div className="modal-body">
                <div className="h5 modal-title text-center">
                  <h4 className="mt-2">
                    <div>Welcome back,</div>
                    <span>Please sign in to your account below.</span>
                  </h4>
                </div>
                <Form id="flogin" onSubmit={submitLogin}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="username"
                          id="username"
                          placeholder="Username here..."
                          defaultValue={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          defaultValue={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password here..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="modal-footer clearfix">
                <div className="float-left">
                  <Button onClick={changeEndPoint} color="link" size="lg">
                    Change Endpoint
                  </Button>
                </div>
                <div className="float-right">
                  <Button
                    type="submit"
                    form="flogin"
                    color="primary"
                    disabled={submitLoad || !username || !password}
                    size="lg"
                  >
                    {submitLoad ? (
                      <Loader type="ball-pulse" color="white" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center text-white opacity-8 mt-3">
            Copyright &copy; Yasunaga Indonesia {moment().format("YYYY")}
          </div>
        </Col>
      </div>
    </div>
  );
}
