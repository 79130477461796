import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../../reducers/ThemeOptions";
import { MainNav, AdminNav } from "./NavItems";

export default function Nav() {
  const enableMobileMenu = useSelector(
    (state) => state.ThemeOptions.enableMobileMenu
  );
  const auth = useSelector((state) => state.auth);
  const [menu, setMenu] = useState();
  const dispatch = useDispatch();
  const toggleMobileSidebar = () => {
    dispatch({
      type: "THEME_OPTIONS/SET_ENABLE_MOBILE_MENU",
      enableMobileMenu: !enableMobileMenu,
    });
    setEnableMobileMenu(!enableMobileMenu);
  };
  useEffect(() => {
    if (auth.user.role_level > 1) {
      let mn = [...MainNav];
      AdminNav.forEach((m) => {
        mn.push(m);
      });
      setMenu(mn);
    } else {
      setMenu(MainNav);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Menu</h5>
      <MetisMenu
        content={menu}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
    </Fragment>
  );
}

// class Nav extends Component {
//   state = {};
//   toggleMobileSidebar = () => {
//     let { enableMobileMenu, setEnableMobileMenu } = this.props;
//     setEnableMobileMenu(!enableMobileMenu);
//   };

//   render() {
//     return (
//       <Fragment>
//         <h5 className="app-sidebar__heading">Menu</h5>
//         <MetisMenu
//           content={MainNav}
//           onSelected={this.toggleMobileSidebar}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//         />
//         {this.props.auth.user ? (
//           this.props.user.role_level > 1 ? (
//             <MetisMenu
//               content={AdminNav}
//               onSelected={this.toggleMobileSidebar}
//               activeLinkFromLocation
//               className="vertical-nav-menu"
//               iconNamePrefix=""
//               classNameStateIcon="pe-7s-angle-down"
//             />
//           ) : (
//             ""
//           )
//         ) : (
//           ""
//         )}
//       </Fragment>
//     );
//   }

//   isPathActive(path) {
//     return this.props.location.pathname.startsWith(path);
//   }
// }
// const mapStateToProps = (state) => ({
//   enableMobileMenu: state.ThemeOptions.enableMobileMenu,
//   auth: state.auth,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
