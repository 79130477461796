import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardBody } from "reactstrap";
import PageTitle from "./PageTitle";

export default function PageSample() {
  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading="Under Construction"
        icon="pe-7s-settings icon-gradient bg-malibu-beach"
      />
      <Card>
        <CardBody>
          <div className="text-center text-danger w-100 display-1">
            HALAMAN INI MASIH DALAM PENGEMBANGAN...
            <br />
            <FontAwesomeIcon
              icon={faSyncAlt}
              className="text-primary"
              size="2x"
              spin
            />
          </div>
        </CardBody>
      </Card>
    </CSSTransitionGroup>
  );
}
