import React from "react";
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import city4 from "../../../assets/utils/images/dropdown-header/city4.jpg";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import UserAvatar from "react-user-avatar";
import { useHistory } from "react-router-dom";

export default function UserBox() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const hist = useHistory();

  const logout = () => {
    Swal.fire({
      title: "Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        hist.push("/login");
        dispatch({ type: "AUTH_LOGOUT" });
      }
    });
  };
  return (
    <div>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <UserAvatar
                    size={42}
                    name={auth.user ? auth.user.name : "Dummy Name"}
                  />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div
                        className="menu-header-image opacity-2"
                        style={{
                          backgroundImage: "url(" + city4 + ")",
                        }}
                      />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <UserAvatar
                                size={42}
                                name={auth.user ? auth.user.name : "Dummy Name"}
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {auth.user ? auth.user.name : "Dummy Name"}
                              </div>
                              <div className="widget-subheading opacity-8">
                                {auth.user
                                  ? auth.user.role.description
                                  : "Rooolll"}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button
                                className="btn-pill btn-shadow btn-shine"
                                color="focus"
                                onClick={logout}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="scroll-area-xs"
                    style={{
                      height: "150px",
                    }}
                  ></div> */}
                  <div className="grid-menu grid-menu-2col">
                    <Row className="no-gutters">
                      <Col sm="6">
                        <Button
                          className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline
                          color="warning"
                          onClick={() => hist.push("/my-activity")}
                        >
                          <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2">
                            {" "}
                          </i>
                          My Activity
                        </Button>
                      </Col>
                      <Col sm="6">
                        <Button
                          className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline
                          color="danger"
                          onClick={() => hist.push("/change-password")}
                        >
                          <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                            {" "}
                          </i>
                          <b>Change Password</b>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading">
                {auth.user ? auth.user.name : "Dummy Name"}
              </div>
              <div className="widget-subheading">
                {auth.user ? auth.user.role.description : "Roooolll"}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
