import React, { useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Card,
  Label,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
} from "reactstrap";
import PageTitle from "../PageTitle";
import FileDownload from "js-file-download";
import Axios from "../../../Axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "react-loaders";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import ListPemasukan from "./ListPemasukan";

export default function UploadPemasukan() {
  const [excelFile, setExcelFile] = useState();
  const [upLoad, setUpLoad] = useState(false);
  const [downLoad, setDownLoad] = useState(false);
  const auth = useSelector((state) => state.auth);

  const getTemplate = () => {
    setDownLoad(true);
    Axios.get(
      `${auth.endPoint}it-inventory/template/pemasukan-barang?api_token=${auth.user.api_token}`,
      {
        responseType: "blob",
      }
    )
      .then(({ data }) =>
        FileDownload(data, "Template Upload Pemasukan Barang.xlsx")
      )
      .finally(() => setDownLoad(false))
      .catch(() => toast.error("Download Template Error!"));
  };

  const submitImport = () => {
    let frm = new FormData();
    frm.append("excel_file", excelFile);
    frm.append("api_token", auth.user.api_token);
    setUpLoad(true);
    Axios.post(`${auth.endPoint}it-inventory/import/pemasukan-barang`, frm)
      .then(({ data }) => toast.success(data))
      .finally(() => setUpLoad(false))
      .catch(() => toast.error("Failed to upload data"));
  };

  return (
    <div>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Upload Pemasukan"
          icon="pe-7s-refresh-cloud icon-gradient bg-malibu-beach"
        />
        <Tabs
          defaultActiveKey="1"
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          <TabPane key="1" tab="Upload">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Upload Pemasukan Barang</CardTitle>
                <FormGroup>
                  <Label>1. Download Template</Label>
                  <Button
                    size="lg"
                    block
                    color="primary"
                    disabled={downLoad}
                    onClick={getTemplate}
                  >
                    {downLoad ? (
                      <Loader type="ball-pulse" color="white" />
                    ) : (
                      <span>
                        <i className="pe-7s-cloud-download"></i> Download
                        Template
                      </span>
                    )}
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Label>2. Pilih File</Label>
                  <Input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => setExcelFile(e.target.files[0])}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>3. Submit</Label>
                  <Button
                    onClick={submitImport}
                    color="success"
                    block
                    size="lg"
                  >
                    {upLoad ? (
                      <Loader type="ball-pulse" color="white" />
                    ) : (
                      <span>
                        <i className="pe-7s-paper-plane" /> Submit
                      </span>
                    )}
                  </Button>
                </FormGroup>
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tab="List Data" key="2">
            <ListPemasukan auth={auth} />
          </TabPane>
        </Tabs>
      </CSSTransitionGroup>
    </div>
  );
}
