import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, Jumbotron } from "reactstrap";

export default function UnauthorizedPage() {
  const [back, setBack] = useState(false);
  const hist = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth.signedIn) {
      Axios.get(`${auth.endPoint}/user?api_token=${auth.user.api_token}`)
        .then(() => setBack(true))
        .catch(() => dispatch({ type: "AUTH_LOGOUT" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Card color="danger">
        <CardBody className="p-3">
          <Jumbotron className="mb-0">
            <h1 className="text-center text-danger display-1">401</h1>
            <h1 className="text-center text-danger display-4">UNAUTHORIZED</h1>
            <div className="w-100 d-flex justify-content-center">
              <Button
                color="link"
                size="lg"
                disabled={!back}
                onClick={() => hist.push("/dashboard")}
              >
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                GO HOME
              </Button>
            </div>
          </Jumbotron>
        </CardBody>
      </Card>
    </div>
  );
}
