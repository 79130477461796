export const MainNav = [
  {
    icon: "pe-7s-rocket",
    label: "Dashboard",
    to: "#/dashboard",
  },
  {
    icon: "lnr-plus-circle",
    label: "1. Pemasukan Barang",
    to: "#/pemasukan-barang",
  },
  {
    icon: "lnr-circle-minus",
    label: "2. Pengeluaran Barang",
    to: "#/pengeluaran-barang",
  },
  {
    icon: "pe-7s-plugin",
    label: "3 Mutasi Bahan Baku",
    to: "#/mutasi-bahan-baku",
  },
  {
    icon: "pe-7s-diamond",
    label: "4. Mutasi Barang Jadi",
    to: "#/mutasi-barang-jadi",
  },
  {
    icon: "pe-7s-gleam",
    label: "5.a Mutasi Mesin",
    to: "#/mutasi-mesin",
  },
  {
    icon: "pe-7s-gleam",
    label: "5.b Mutasi Peralatan & Tools",
    to: "#/mutasi-peralatan",
  },
  {
    icon: "pe-7s-bandaid",
    label: "6.a Mutasi Barang Reject",
    to: "#/mutasi-barang-reject",
  },
  {
    icon: "pe-7s-bandaid",
    label: "6.b Mutasi Barang Scrap",
    to: "#/mutasi-barang-scrap",
  },
  {
    icon: "pe-7s-map-2",
    label: "7. Posisi WIP",
    to: "#/posisi-wip",
  },
];

export const AdminNav = [
  {
    icon: "lnr-user",
    label: "Users",
    to: "#/users",
  },
  {
    icon: "pe-7s-timer",
    label: "User Log",
    to: "#/user-log",
  },
  {
    icon: "pe-7s-note",
    label: "Catatan Exim",
    to: "#/exim-notes",
  },
  {
    icon: "pe-7s-refresh-cloud",
    label: "Upload",
    content: [
      {
        label: "Pengeluaran",
        to: "#/upload-pengeluaran",
      },
      {
        label: "Pemasukan",
        to: "#/upload-pemasukan",
      },
      {
        label: "Barang Reject",
        to: "#/upload-barang-reject",
      },
    ],
  },
];
