import React, { useState } from "react";
import { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../PageTitle";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import ReactTable from "react-table";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Axios from "../../../Axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faSyncAlt,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function UserLog() {
  const [tblData, setTblData] = useState([]);
  const [tblLoad, setTblLoad] = useState(false);
  const [tglDari, setTglDari] = useState(
    moment()
      .startOf("year")
      .toDate()
  );
  const [tglSampai, setTglSampai] = useState(new Date());
  const auth = useSelector((state) => state.auth);

  const mapboxToken =
    "sk.eyJ1IjoiaXQteWFzdW5hZ2EiLCJhIjoiY2tuNzdpaHlsMDU3MTJubXE1bHl1dmRidCJ9._D5pztPvfeSpUn3gb-qfYA";
  const getData = () => {
    setTblLoad(true);
    Axios.post(`${auth.endPoint}admin/user-log/get-data-tbl`, {
      api_token: auth.user.api_token,
      tglDari,
      tglSampai,
    })
      .then(({ data }) => setTblData(data))
      .finally(() => setTblLoad(false))
      .catch(({ response }) => {
        toast.error("Gagal mengambil data");
        console.log(response);
      });
  };

  const trackLocation = (ip) => {
    setTblLoad(true);
    Axios.get(`https://ipapi.co/${ip}/json`)
      .then(({ data }) => {
        var htm = '<table class="table table-striped table-sm"><tbody>';
        htm += `<tr><td>Kota</td><td>${data.city}</td></tr>`;
        htm += `<tr><td>Negara</td><td>${data.country_name}</td></tr>`;
        htm += `<tr><td>Provider</td><td>${data.org}</td></tr>`;
        htm +=
          "</tbody></table><img class='img-fluid' src='https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/" +
          data.longitude +
          "," +
          data.latitude +
          ",8/400x400?access_token=" +
          mapboxToken +
          "' />";
        Swal.fire({
          title: "Tracking Success",
          icon: "success",
          html: htm,
        });
      })
      .finally(() => setTblLoad(false))
      .catch(() =>
        Swal.fire({
          title: "Tracking gagal",
          timer: 2000,
          icon: "error",
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
        })
      );
  };

  const columns = [
    {
      Header: "Name",
      accessor: "user_name",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Activity",
      accessor: "activity",
    },
    {
      Header: "Origin",
      accessor: "origin",
      Cell: (c) =>
        c.value ? (
          <div className="d-flex justify-content-center">
            {c.value}
            <FontAwesomeIcon
              onClick={() => trackLocation(c.value)}
              icon={faLocationArrow}
              className="ml-2 text-success"
              style={{ cursor: "pointer" }}
              id={"t" + c.original.id}
            />
            <UncontrolledTooltip target={"t" + c.original.id}>
              Track Location
            </UncontrolledTooltip>
          </div>
        ) : (
          "-"
        ),
    },
    {
      Header: "Waktu",
      accessor: "waktu",
    },
  ];
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="User Log Activity"
          subheading="Log Aktivitas semua user"
          icon="pe-7s-timer icon-gradient bg-malibu-beach"
        />
        <Card className="mb-4">
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">Start</div>
                    </InputGroupAddon>
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      selected={tglDari}
                      selectsStart
                      maxDate={tglSampai}
                      className="form-control"
                      startDate={tglDari}
                      endDate={tglSampai}
                      onChange={(e) => setTglDari(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">End</div>
                    </InputGroupAddon>
                    <DatePicker
                      selected={tglSampai}
                      dateFormat="dd MMM yyyy"
                      selectsEnd
                      className="form-control"
                      startDate={tglDari}
                      endDate={tglSampai}
                      minDate={tglDari}
                      onChange={(e) => setTglSampai(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button id="btnShow" disabled={tblLoad} onClick={getData}>
                        <FontAwesomeIcon
                          className="btn-icon-wrapper"
                          icon={tblLoad ? faSyncAlt : faTable}
                          spin={tblLoad}
                        />
                      </Button>
                      <UncontrolledTooltip
                        placement="right-end"
                        target="btnShow"
                      >
                        Show Data
                      </UncontrolledTooltip>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <ReactTable
              filterable
              data={tblData}
              columns={columns}
              loading={tblLoad}
              defaultPageSize="10"
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
}
