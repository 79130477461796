import {
  faFileExcel,
  faFilePdf,
  faSyncAlt,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import PageTitle from "../PageTitle";
import DatePicker from "react-datepicker";
import moment from "moment";
import Axios from "../../../Axios";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import CountUp from "react-countup";
import FileDownload from "js-file-download";

export default function MutasiPeralatan() {
  const auth = useSelector((state) => state.auth);
  const [tglDari, setTglDari] = useState(
    moment()
      .startOf("month")
      .toDate()
  );
  const [tglSampai, setTglSampai] = useState(new Date());
  const [tblData, setTblData] = useState([]);
  const [tblLoad, setTblLoad] = useState(false);
  const [excelLoad, setExcelLoad] = useState(false);
  const [pdfLoad, setPdfLoad] = useState(false);
  const [winHeight, setWinHeight] = useState(window.innerHeight / 1.5);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWinHeight(window.innerHeight / 1.5);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWinHeight(window.innerHeight / 1.5);
      });
    };
  }, []);

  const getData = () => {
    setTblLoad(true);
    Axios.post(auth.endPoint + "it-inventory/report/mutasi-peralatan", {
      tgl_dari: moment(tglDari).format("YYYY-MM-DD"),
      tgl_sampai: moment(tglSampai).format("YYYY-MM-DD"),
      api_token: auth.user.api_token,
    })
      .then(({ data }) => {
        setTblData(data);
        let publicIp = "";
        Axios.get("https://api.ipify.org/?format=json")
          .then(({ data }) => (publicIp = data.ip))
          .finally(() => {
            Axios.post(`${auth.endPoint}log`, {
              api_token: auth.user.api_token,
              origin: publicIp,
              category: "DATA ACCESS",
              activity:
                "Melihat Mutasi peralatan periode " +
                moment(tglDari).format("DD MMM YYYY") +
                " - " +
                moment(tglSampai).format("DD MMM YYYY"),
            });
          });
      })
      .catch((er) => Swal.fire("Error!", "Server Error...", "error"))
      .finally(() => setTblLoad(false));
  };

  const downloadExcel = () => {
    setExcelLoad(true);
    Axios.post(
      auth.endPoint + "it-inventory/report/mutasi-peralatan/export",
      {
        type: "EXCEL",
        tgl_dari: moment(tglDari).format("YYYY-MM-DD"),
        tgl_sampai: moment(tglSampai).format("YYYY-MM-DD"),
        api_token: auth.user.api_token,
      },
      {
        responseType: "blob",
      }
    )
      .then((res) => {
        let publicIp = "";
        Axios.get("https://api.ipify.org/?format=json")
          .then(({ data }) => (publicIp = data.ip))
          .finally(() => {
            Axios.post(`${auth.endPoint}log`, {
              api_token: auth.user.api_token,
              origin: publicIp,
              category: "DATA DOWNLOAD",
              activity:
                "Download Excel Mutasi peralatan periode " +
                moment(tglDari).format("DD MMM YYYY") +
                " - " +
                moment(tglSampai).format("DD MMM YYYY"),
            });
          });
        return FileDownload(
          res.data,
          "Laporan Mutasi Peralatan & Tools Periode " +
            moment(tglDari).format("DD MMM YYYY") +
            " - " +
            moment(tglSampai).format("DD MMM YYYY") +
            ".xlsx"
        );
      })
      .catch((er) => Swal.fire("Error!", "Server Error", "error"))
      .finally(() => setExcelLoad(false));
  };
  const downloadPdf = () => {
    setPdfLoad(true);
    Axios.post(
      auth.endPoint + "it-inventory/report/mutasi-peralatan/export",
      {
        type: "PDF",
        tgl_dari: moment(tglDari).format("YYYY-MM-DD"),
        tgl_sampai: moment(tglSampai).format("YYYY-MM-DD"),
        api_token: auth.user.api_token,
      },
      {
        responseType: "blob",
      }
    )
      .then((res) => {
        let publicIp = "";
        Axios.get("https://api.ipify.org/?format=json")
          .then(({ data }) => (publicIp = data.ip))
          .finally(() => {
            Axios.post(`${auth.endPoint}log`, {
              api_token: auth.user.api_token,
              origin: publicIp,
              category: "DATA DOWNLOAD",
              activity:
                "Download PDF Mutasi peralatan periode " +
                moment(tglDari).format("DD MMM YYYY") +
                " - " +
                moment(tglSampai).format("DD MMM YYYY"),
            });
          });
        return FileDownload(
          res.data,
          "Laporan Mutasi Peralatan & Tools Periode " +
            moment(tglDari).format("DD MMM YYYY") +
            " - " +
            moment(tglSampai).format("DD MMM YYYY") +
            ".pdf"
        );
      })
      .catch((er) => Swal.fire("Error!", "Server Error", "error"))
      .finally(() => setPdfLoad(false));
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        Cell: ({ index }) => index + 1,
        width: 40,
      },
      {
        Header: "Kode Barang",
        accessor: "item_no",
        maxWidth: 125,
      },
      {
        Header: "Nama Barang",
        accessor: "nama_barang",
      },
      {
        Header: "Satuan",
        accessor: "satuan",
        maxWidth: 100,
      },
      {
        Header: "Saldo Awal",
        accessor: "saldo_awal",
        width: 100,
      },
      {
        Header: "Total Pemasukan",
        accessor: "pemasukan",
        width: 100,
      },
      {
        Header: "Total Pengeluaran",
        accessor: "pengeluaran",
        width: 100,
      },
      {
        Header: "Penyesuaian",
        accessor: "penyesuaian",
        width: 100,
      },
      {
        Header: "Saldo Akhir",
        accessor: "saldo_akhir",
        width: 100,
      },
    ],
    []
  );
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Mutasi Peralatan &amp; Tools"
          subheading="Laporan Mutasi Peralatan &amp; Tools"
          icon="pe-7s-gleam icon-gradient bg-mean-fruit"
        />
        <Card className="mb-4">
          <CardBody>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">Start</div>
                    </InputGroupAddon>
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      selected={tglDari}
                      selectsStart
                      maxDate={tglSampai}
                      className="form-control"
                      startDate={tglDari}
                      endDate={tglSampai}
                      onChange={(e) => setTglDari(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">End</div>
                    </InputGroupAddon>
                    <DatePicker
                      selected={tglSampai}
                      dateFormat="dd MMM yyyy"
                      selectsEnd
                      className="form-control"
                      startDate={tglDari}
                      endDate={tglSampai}
                      minDate={tglDari}
                      onChange={(e) => setTglSampai(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button id="btnShow" disabled={tblLoad} onClick={getData}>
                        <FontAwesomeIcon
                          className="btn-icon-wrapper"
                          icon={tblLoad ? faSyncAlt : faTable}
                          spin={tblLoad}
                        />
                      </Button>
                      <UncontrolledTooltip
                        placement="right-end"
                        target="btnShow"
                      >
                        Show Data
                      </UncontrolledTooltip>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={4} className="d-flex">
                <div className="mx-auto my-auto">
                  <>
                    <Button
                      id="btnExcel"
                      className="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-dashed"
                      disabled={excelLoad}
                      outline
                      color="success"
                      onClick={downloadExcel}
                    >
                      <FontAwesomeIcon
                        className="btn-icon-wrapper"
                        icon={excelLoad ? faSyncAlt : faFileExcel}
                        spin={excelLoad}
                      />
                    </Button>
                    <UncontrolledTooltip
                      placement="bottom-end"
                      target="btnExcel"
                    >
                      Download Excel
                    </UncontrolledTooltip>
                  </>
                  <Button
                    id="btnPdf"
                    className="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-dashed"
                    disabled={pdfLoad}
                    outline
                    color="danger"
                    onClick={downloadPdf}
                  >
                    <FontAwesomeIcon
                      className="btn-icon-wrapper"
                      icon={pdfLoad ? faSyncAlt : faFilePdf}
                      spin={pdfLoad}
                    />
                  </Button>
                  <UncontrolledTooltip placement="bottom-end" target="btnPdf">
                    Download PDF
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardBody className="p-0">
            <ReactTable
              filterable
              getTheadTrProps={() => {
                return {
                  style: { fontSize: "9pt" },
                };
              }}
              getTdProps={() => {
                return {
                  style: {
                    fontSize: "9pt",
                  },
                };
              }}
              columns={columns}
              data={tblData.data}
              loading={tblLoad}
              className="-striped -highlight -fixed"
              style={{ height: winHeight }}
            />
          </CardBody>
        </Card>
        {tblData.data ? (
          <Row>
            <Col>
              <Card className="mb-4">
                <CardBody className="d-block" height={500}>
                  <div className="mx-auto my-auto">
                    <p className="text-center display-3">TOTAL</p>
                    <p className="display-1 text-center text-primary">
                      <CountUp end={tblData.data.length} duration={5} />
                    </p>
                    <p className="text-center display-4">RECORDS</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </CSSTransitionGroup>
    </Fragment>
  );
}
