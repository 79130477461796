import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Components/Layouts";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import reducers from "./reducers";

const rootElement = document.getElementById("root");
const store = configureStore({
  reducer: reducers,
});

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Components/Layouts", () => {
    const NextApp = require("./Components/Layouts").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
